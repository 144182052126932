<script setup lang="ts">
import IconMagazine from '@/assets/icons/magazine.svg'

const props = defineProps({
  iconGray: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <BaseBadge>
    <IconMagazine :class="{ 'text-s-500': props.iconGray }" />
  </BaseBadge>
</template>
