<script setup lang="ts">
import IconChart from '@/assets/icons/chart.svg'
const props = defineProps({
  iconGray: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <BaseBadge>
    <IconChart :class="{ 'text-s-500': props.iconGray }" />
  </BaseBadge>
</template>
