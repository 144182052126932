<script setup lang="ts">
import IconVideo from '@/assets/icons/video.svg'

const props = defineProps({
  iconGray: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <BaseBadge>
    <IconVideo :class="{ 'text-s-500': props.iconGray }" />
  </BaseBadge>
</template>
