<script setup lang="ts">
import IconArticle from '@/assets/icons/article.svg'

const props = defineProps({
  iconGray: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <BaseBadge>
    <IconArticle :class="{ 'text-s-500': props.iconGray }" />
  </BaseBadge>
</template>
